/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-22 19:05:25
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-22 19:06:52
 */
import Finance from './Finance.vue'
export default Finance
