/*
 * @Descripttion: *
 * @version: *
 * @Author: Fengshi
 * @Date: 2020-11-22 19:05:30
 * @LastEditors: Mingkit
 * @LastEditTime: 2021-01-14 15:14:31
 */
import FinanceTable from './FinanceTable'
import Account from './account'
import { mapGetters } from 'vuex'
export default {
  name: 'Finance',
  components: {
    Account,
    FinanceTable
  },
  computed: {
    ...mapGetters([
      'can_buy_combo'
    ])
  }
}
